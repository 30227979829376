<template>
  <v-dialog v-model="showDialog" persistent width="600">
    <v-card class="pa-6" rounded="lg">
      <!-- TITLE -->
      <v-card-title class="justify-center">
        <h5
          class="md:text-base text-sm flex items-center justify-center whitespace-normal text-center"
        >
          {{ i18n.t('text.selected_documents', { n: documents.length }) }}
        </h5>
      </v-card-title>
      <!-- /TITLE -->

      <div class="mb-6">
        <!-- Ongoing state -->
        <ListOfDocuments
          v-if="batchState === 'ongoing' || batchState === 'idle'"
          :documents="documents"
          :batch-state="batchState"
          @remove-item="handleRemoveDocument"
        />
        <!-- Success state -->
        <SuccessBatchProcess
          v-else-if="batchState === 'completed'"
          :documents="signedDocuments"
        />
      </div>

      <!-- More than 14 documents state -->
      <div
        v-if="
          documents &&
          documents.length > 14 &&
          (batchState === 'idle' || batchState === 'ongoing')
        "
        class="mb-10 p-3 bg-yellow-50 border border-yellow-300 rounded grid"
      >
        {{ i18n.t('batch.info.over_fourteen_files') }}
      </div>

      <!-- Error state -->
      <div
        v-if="batchState === 'error'"
        class="mb-10 p-3 bg-red-50 border border-red-300 rounded grid"
      >
        {{ batchError }}
      </div>

      <!-- ACTIONS -->
      <DeepButton
        v-if="batchState !== 'completed'"
        :action="batchSignSimpleDocuments"
        class="mb-3"
        color="primary"
        :text="
          batchState === 'ongoing'
            ? i18n.t('batch.preparing_files')
            : i18n.t('buttons.sign_now')
        "
        :class="{ 'pointer-events-none': batchState === 'ongoing' }"
        :show-loader-and-text="batchState === 'ongoing'"
      />
      <DeepButton
        :action="closeDialog"
        variant="outlined"
        :class="{ 'pointer-events-none': batchState === 'ongoing' }"
        :text="
          batchState === 'completed'
            ? i18n.t('buttons.close')
            : i18n.t('buttons.cancel')
        "
      />
      <!-- /ACTIONS -->
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onUnmounted, computed } from 'vue';
import { useEmitter } from '@/composables/useEmitter';
import { useI18n } from 'vue-i18n';
import { batchSign, batchStatus } from '@/api/deepsign/batch';
import { SignStatus } from '@/types/enums/SignStatus';
import type { Document } from '@/types/Document';
import type { BatchSignDocument } from '@/types/deepsign/BatchSignDocument';

const i18n = useI18n();
const emitter = useEmitter();

const showDialog = ref(false);
const documents = ref<Document[]>([]);
const signedDocuments = ref<BatchSignDocument[]>([]);
const batchState = ref<'idle' | 'ongoing' | 'error' | 'completed'>('idle');
const batchSigningTimeout = ref();
const batchError = ref();
const showHint = ref(false);
const batchSignResponse = ref();
const pollInterval = ref();
const emit = defineEmits(['handle-signed-documents']);

const signKeys = computed(() => {
  return documents.value
    .filter(
      (document) => document.signKey && document.signatureType === 'signature'
    )
    .map((document) => document.signKey);
});

emitter.$on('open-simple-batch-process-dialog', openDialog);

onUnmounted(() => {
  clearTimeout(batchSigningTimeout.value);
  emitter.$off('open-simple-batch-process-dialog', openDialog);
});

function onSuccess() {
  batchState.value = 'completed';
  emitter.$emit('update-signed-documents');
}

function openDialog(eventData) {
  documents.value = eventData.value;
  showDialog.value = true;
}

function closeDialog() {
  if (batchState.value === 'completed') {
    emit('handle-signed-documents');
  }
  documents.value = [];
  showDialog.value = false;
  batchState.value = 'idle';
  batchError.value = undefined;
  showHint.value = false;
}

async function batchSignSimpleDocuments() {
  const formData = new FormData();
  const data = JSON.stringify({
    signKeys: signKeys.value
  });
  formData.append('data', new Blob([data], { type: 'application/json' }));
  try {
    batchState.value = 'ongoing';
    const response = await batchSign(formData);
    batchSignResponse.value = response.data;
    if (response.data.batchStatus === 'completed') {
      if (response.data.documents) {
        const allDocumentsAreSigned = response.data.documents.every(
          (document: Document) => document.signStatus === SignStatus.SIGNED
        );
        if (allDocumentsAreSigned) {
          // A little de
          batchSigningTimeout.value = setTimeout(() => {
            signedDocuments.value = response.data.documents;
            onSuccess();
          }, 1500);
        }
      }
    } else if (response.data.batchStatus === SignStatus.IN_PROGRESS) {
      checkAsynchronousSign();
    }
  } catch (error) {
    batchState.value = 'error';
    if (error.response && error.response.data.message) {
      batchError.value = error.response.data.message;
    } else {
      batchError.value = i18n.t('batch.error.generic_error');
    }
  }
}

function checkAsynchronousSign() {
  try {
    pollInterval.value = setInterval(() => {
      getBatchStatus(batchSignResponse.value?.batchKey);
    }, 1000);
  } catch (error) {
    console.error('Error polling sign status: ', error);
    batchState.value = 'error';
    if (error.response && error.response.data.message) {
      batchError.value = error.response.data.message;
    } else {
      batchError.value = i18n.t('batch.error.generic_error');
    }
  }
}

function handleRemoveDocument(documentId: string) {
  documents.value = documents.value.filter(
    (document) => document.documentId !== documentId
  );

  if (documents.value && documents.value.length <= 2) {
    showHint.value = true;
  }
}

async function getBatchStatus(signKey: string) {
  try {
    const response = await batchStatus(signKey);

    if (response.data.batchStatus === 'completed') {
      const allDocumentsHaveBeenSigned = response.data.documents.every(
        (document: BatchSignDocument) =>
          document.signStatus === SignStatus.SIGNED
      );
      // Only if all documents were signed, we show success
      if (allDocumentsHaveBeenSigned) {
        signedDocuments.value = response.data.documents;
        onSuccess();
        stopPolling();
      }
    }
  } catch (error) {
    batchState.value = 'error';
    batchError.value = i18n.t('batch.error.generic_error');
    stopPolling();
  }
}

function stopPolling() {
  clearInterval(pollInterval.value);
}
</script>
